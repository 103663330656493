'use client';

import { GoogleTagManager } from '@next/third-parties/google';
import { useEffect, useState } from 'react';
import { hotjar } from 'react-hotjar';
import { usePathname } from 'next/navigation';
import { datadogRum } from '@datadog/browser-rum';

import NaverWcsLog from '../NaverWcsLog';
import Mixpanel from '../Mixpanel';
import ChannelTalk from '../ChannelTalk';

import { decodeAccessToken, getReferrerService } from '@/shared/utils';
import { useNextUrl } from '@/shared/hooks';
import { ProfileContextType } from '@/entities/profile';
import { ClientAuth } from '@/shared/auth';

type ServiceIds = Record<string, { gtm: string; hotjar: string }>;

const SERVICE_ID: ServiceIds = {
  yozmit: {
    gtm: process.env.NEXT_PUBLIC_GTM_ID_YOZMIT as string,
    hotjar: process.env.NEXT_PUBLIC_HOTJAR_ID_YOZMIT as string,
  },
  wishket: {
    gtm: process.env.NEXT_PUBLIC_GTM_ID_WISHKET as string,
    hotjar: process.env.NEXT_PUBLIC_HOTJAR_ID as string,
  },
};

const MarketingScriptRouter = () => {
  const [isMounted, setIsMounted] = useState(false);
  const pathName = usePathname();
  const { nextUrl } = useNextUrl();
  const referrerService = getReferrerService(nextUrl);
  const [profile, setProfile] = useState<ProfileContextType | undefined>(
    undefined
  );

  const getProfile = async () => {
    const accessToken = await ClientAuth.getAccessToken();

    if (accessToken) {
      const decodedToken = decodeAccessToken(accessToken);

      if (decodedToken) setProfile(decodedToken);
    }
  };

  const service =
    referrerService === 'yozmit' || referrerService === 'wishket'
      ? referrerService
      : profile?.first_signup_service || '';
  const { gtm: GTM_ID, hotjar: HOTJAR_ID } = SERVICE_ID[service] || {};

  useEffect(() => {
    const initializeMarketingScripts = async () => {
      setIsMounted(true);
      await getProfile();

      if (HOTJAR_ID) {
        hotjar.initialize({
          id: Number(HOTJAR_ID),
          sv: 6,
        });
      }
    };

    initializeMarketingScripts();
  }, [pathName, service, HOTJAR_ID]);

  useEffect(() => {
    if (!isMounted) return;

    const botPattern = new RegExp('(googlebot/|bot|Googlebot-Mobile|~)', 'i');
    const isBot = botPattern.test(navigator.userAgent);
    const sampleRate = isBot ? 0 : 10;

    datadogRum.init({
      applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID as string,
      clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN as string,
      site: 'datadoghq.com',
      service: 'auth',
      env: process.env.NEXT_PUBLIC_APP_STAGE,
      sessionSampleRate: sampleRate,
      sessionReplaySampleRate: sampleRate,
      defaultPrivacyLevel: 'allow',
      allowedTracingUrls: [
        /https:\/\/.*\.wishdev\.net/,
        /https:\/\/.*\.wishket\.com/,
        process.env.NEXT_PUBLIC_JOBS_URL as string,
        process.env.NEXT_PUBLIC_APP_BASE_API_URL as string,
        process.env.NEXT_PUBLIC_APP_BASE_URL as string,
        process.env.NEXT_PUBLIC_WISHKET_URL as string,
        process.env.NEXT_PUBLIC_YOZMIT_URL as string,
        (url) => url.startsWith(process.env.NEXT_PUBLIC_JOBS_URL as string),
        (url) =>
          url.startsWith(process.env.NEXT_PUBLIC_APP_BASE_API_URL as string),
        (url) => url.startsWith(process.env.NEXT_PUBLIC_WISHKET_URL as string),
        (url) => url.startsWith(process.env.NEXT_PUBLIC_YOZMIT_URL as string),
        (url) => url.startsWith(process.env.NEXT_PUBLIC_APP_BASE_URL as string),
      ],
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
    });
  }, [isMounted]);

  if (!isMounted) return <></>;

  return (
    <>
      {GTM_ID && <GoogleTagManager gtmId={String(GTM_ID)} />}
      <NaverWcsLog />
      <Mixpanel profile={profile} pathName={pathName} />
      <ChannelTalk profile={profile} />
    </>
  );
};

export default MarketingScriptRouter;
