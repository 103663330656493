'use client';

import Script from 'next/script';
import { useEffect, useState } from 'react';

import { MarketingProps } from '@/shared/types';

const NaverWcsLog = ({ pathName }: MarketingProps) => {
  const [isInit, setIsInit] = useState(false);
  const scriptLoadHandler = () => {
    setIsInit(true);
  };

  useEffect(() => {
    if (isInit) {
      try {
        if (!window.wcs_add) window.wcs_add = {};
        window.wcs_add['wa'] = process.env.NEXT_PUBLIC_APP_NAVER_WCS_ID || '';
        if (!window._nasa) window._nasa = {};
        window.wcs.inflow();
        window.wcs_do(window._nasa);
      } catch (e) {
        /* empty */
      }
    }
  }, [isInit, pathName]);

  return (
    <Script
      src="//wcs.naver.net/wcslog.js"
      strategy="afterInteractive"
      onLoad={scriptLoadHandler}
    />
  );
};

export default NaverWcsLog;
