import mixpanel from 'mixpanel-browser';

const MIXPANEL_ID = process.env.NEXT_PUBLIC_MIXPANEL_ID;

mixpanel.init(String(MIXPANEL_ID), {
  debug: false,
  persistence: 'localStorage',
});

class MixpanelActions {
  static identify(id: string) {
    mixpanel.identify(id);
  }

  static track(event: string, props?: Record<string, unknown>) {
    mixpanel.track(event, props);
  }

  static peopleSet(props: Record<string, unknown>) {
    mixpanel.people.set(props);
  }
}

export default MixpanelActions;
