'use client';

import { useEffect } from 'react';
import * as ChannelService from '@channel.io/channel-web-sdk-loader';

import { MarketingProps } from '@/shared/types';

const ChannelTalk = ({ profile }: MarketingProps) => {
  const { username, email } = profile || {};

  useEffect(() => {
    ChannelService.loadScript();
    if (username && email) {
      ChannelService.boot({
        pluginKey: process.env.NEXT_PUBLIC_APP_CHANNEL_TALK_PLUGIN_KEY || '',
        memberId: username,
        profile: {
          name: username,
          email: email,
          구분: '통합 계정 -',
        },
      });
    } else {
      ChannelService.boot({
        pluginKey: process.env.NEXT_PUBLIC_APP_CHANNEL_TALK_PLUGIN_KEY || '',
        profile: {
          구분: '통합 계정',
        },
      });
    }
  }, [username, email]);

  return <></>;
};

export default ChannelTalk;
