'use client';

import { useEffect } from 'react';

import MixpanelActions from './MixpanelActions';

import { PATH } from '@/shared/constants';
import { MarketingProps } from '@/shared/types';

const Mixpanel = ({ profile, pathName }: MarketingProps) => {
  const { username, user_id, email } = profile || {};

  useEffect(() => {
    if (username) {
      MixpanelActions.identify(`${user_id}`);
      MixpanelActions.peopleSet({
        $name: username,
        $email: email,
      });
    }
  }, [username, email, user_id]);

  useEffect(() => {
    if (pathName === PATH.SIGNUP_COMPLETE) {
      MixpanelActions.track('Sign Up');
    }
    MixpanelActions.track('page View');
  }, [pathName]);

  return <></>;
};

export default Mixpanel;
